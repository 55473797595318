@import "~bootstrap/scss/bootstrap";
:root {
  font-size: 16px;
  --bs-body-bg: #000000;
}
@font-face {
  font-family: "General Sans Variable";
  src: url("/assets/fonts/GeneralSans-Variable.ttf") format("truetype");
  font-weight: 100 900;
}
@font-face {
  font-family: "IBM Plex Mono";
  font-display: swap;
  src: local("IBMPlexMono-Medium"),
    url(./assets/fonts/IBMPlexMono-Medium.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: "General Sans Variable" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  margin: auto;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  width: 100%;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1340px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1340px;
  }
  .container-xl,
  .container-xxl {
    max-width: 1340px;
  }
}

.mainSection {
  display: flex;
  align-items: center;
  place-items: center;
  min-height: calc(100vh - 72px);
  width: 100%;
}

@media only screen and (max-width: 900px) {
  :root {
    font-size: 14px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
}

.spinnerWraper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.spinner {
  position: relative;
  width: 22.4px;
  height: 22.4px;
}

.spinner::before,
.spinner::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  animation: spinner-b4c8mmmd 0.5s backwards,
    spinner-49opz7md 1.25s 0.5s infinite ease;
  border: 5.6px solid #474bff;
  border-radius: 50%;
  box-shadow: 0 -33.6px 0 -5.6px #474bff;
  position: absolute;
}

.spinner::after {
  animation-delay: 0s, 1.25s;
}

@keyframes spinner-b4c8mmmd {
  from {
    box-shadow: 0 0 0 -5.6px #474bff;
  }
}

@keyframes spinner-49opz7md {
  to {
    transform: rotate(360deg);
  }
}

.modalBackdrop {
  background: rgba(0, 0, 0, 1);
}

.modal-content {
  border-radius: 26px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  background: #101010;
  .modal-header {
    border: none;
    .modal-title {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .modal-body {
    padding: 0;
    .upperSection {
      justify-content: space-between;
      text-align: center;
      display: flex;
      border-top: 1px solid #181818;
      border-bottom: 1px solid #181818;
      padding: 10px 30px;
      .left {
        border-right: 1px solid #181818;
        width: 50%;
      }
      .right {
        width: 50%;
      }
      label {
        color: #899098;
        font-family: General Sans Variable;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      h1 {
        justify-content: center;
        color: #fff;
        font-family: IBM Plex Mono;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        margin: 0;
        sub {
          color: #fff;
          font-family: IBM Plex Mono;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 2px;
          margin-left: 2px;
        }
      }
    }

    .inputArea {
      width: 80%;
      margin: 20px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #000;
      padding: 0 10px;
      border: 1px solid #181818;
      border-radius: 10px;
      .inputBox {
        width: 100%;
        height: 55px;
        color: #ffffff;
        background: #000;
        border: none;
        border-left: 1px solid #181818;
        border-right: 1px solid #181818;
        padding: 0 10px;
        &:focus {
          outline: none;
        }
      }
      img {
        padding-right: 10px;
      }
      button {
        border: none;
        color: #fff279;
        font-family: General Sans Variable;
        font-size: 16px;
        font-style: normal;
        font-weight: 530;
        line-height: normal;
        background: transparent;
        height: 55px;
      }
    }

    .buttonSection {
      width: 80%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin: 30px auto;
      .connectButton {
        border-radius: 12px;
        border: 1px solid #fff;
        background: #3b5aff;
        color: #fff;
        font-family: General Sans Variable;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 56px;
        border: none;
        &:disabled {
          opacity: 0.6;
        }
      }
      .infoTag {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #171717;
        color: #899098;
        font-family: General Sans Variable;
        font-size: 14px;
        font-weight: 500;
        p {
          margin: 0;
        }
        span {
          color: #fff;
          font-family: General Sans Variable;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}

.sliderWraper {
  width: 80%;
  margin: 25px auto;
  .sliderInfo {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    color: #899098;
    font-family: IBM Plex Mono;
    font-size: 0.75rem;
    font-weight: 500;
  }
}

#range3,
#stakeSlider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 8px;
  // background: linear-gradient(to right, #31c859 60%, #fff 60%);
}

#range3::-webkit-slider-thumb,
#stakeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  background: transparent;
  background-size: cover;
  border-radius: 50%;
}

#range3::-moz-range-thumb,
#stakeSlider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  background: transparent;
  background-size: cover;
  border-radius: 50%;
}

#range3::-webkit-slider-thumb {
  background: #31c859;
}

#range3::-moz-range-thumb {
  background: #31c859;
}

#stakeSlider::-webkit-slider-thumb {
  background: #31c859;
}

#stakeSlider::-moz-range-thumb {
  background: #31c859;
}
.dropdown-menu.show {
  min-width: fit-content;
  background: #0000;
}

@media only screen and (max-width: 900px) {
  .modal-content {
    border-radius: 16px;
    .modal-body {
      padding: 0;
      .upperSection {
        padding: 10px 10px;
        .left {
          width: 50%;
        }
        .right {
          width: 50%;
        }
        label {
          font-size: 14px;
        }
        h1 {
          font-size: 14px;

          sub {
            font-size: 12px;
            margin-bottom: 3px;
            margin-left: 2px;
          }
        }
      }

      .inputArea {
        width: 90%;
        margin: 20px auto;

        .inputBox {
          height: 44px;
          border-radius: 0;
          &:focus {
            outline: none;
          }
        }
        img {
          padding-right: 10px;
        }
        button {
          font-size: 16px;
          height: 44px;
        }
      }

      .buttonSection {
        width: 90%;

        gap: 30px;
        margin: 20px auto;
        .connectButton {
          font-size: 16px;
          height: 44px;
        }
        .infoTag {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          background: #171717;
          color: #899098;
          font-family: General Sans Variable;
          font-size: 14px;
          font-weight: 500;
          p {
            margin: 0;
          }
          span {
            color: #fff;
            font-family: General Sans Variable;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }
}
