.box {
  margin: auto;
  width: 100%;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      color: #fff;
      font-family: General Sans Variable;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      max-width: 350px;
    }
    p {
      color: #8d8d8d;
      font-family: General Sans Variable;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      max-width: 500px;
      span {
        color: #fff;
        font-family: General Sans Variable;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  .infoBarOuter {
    border-top: 1px solid #2b313a;
    border-bottom: 1px solid #2b313a;
    margin: 20px 0;
    .infoBar {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .leftSide {
        border-radius: 12px;
        border: 1px solid #252525;
        background: #171717;
        padding: 7px 30px;
        display: flex;
        align-items: center;
        .timer {
          width: 300px;
          display: flex;
          gap: 10px;
          padding-right: 30px;
          &.closed {
            width: fit-content;
            display: flex;
            align-items: center;
            padding: 6px 0;
            .infoIcon {
              height: 16px;
              border-right: 1px solid #252525;
              padding-right: 20px;
            }
            h5 {
              color: #fff;
              font-family: General Sans Variable;
              font-size: 16px;
              font-weight: 500;
              margin: 0;
            }
          }
          span {
            color: #fff;
            font-size: 28px;
            font-weight: 500;
            display: flex;
            align-items: center;
            sub {
              color: #a1a1a1;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
        .info {
          width: 100px;
          padding-left: 20px;
          border-left: 1px solid #2a2a2a;
          color: #fff;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .rightSide {
        width: fit-content;
        align-items: center;
        border: 1px solid #2b313a;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        padding: 9px 9px;
        .walletAddress {
          margin-left: 10px;
          button {
            background: transparent !important;
            border: none !important;
            color: #fff !important;
          }
        }
        .coin {
          align-items: center;
          background: #1b1c1e;
          border-radius: 8px;
          color: #fff;
          display: flex;
          margin-right: 0;
          padding: 7px 15px;
          .bullIcon {
            height: 20px;
            margin-right: 10px;
          }
          .symbol {
            color: #8e98a6;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .terms {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 30px;
    .term {
      border-radius: 26px;
      border: 1px solid rgba(255, 255, 255, 0.06);
      background: #101010;
      .header {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1 {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #fff;
          font-family: General Sans Variable;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        p {
          display: flex;
          align-items: center;
          gap: 3px;
          padding: 5px 15px;
          border-radius: 5px;
          background: #1e1e1e;
          color: #fff;
          text-align: center;
          font-family: General Sans Variable;
          font-size: 16px;
          font-style: normal;
          font-weight: 530;
          line-height: normal;
        }
      }
      .info {
        border-top: 1px solid #181818;
        padding: 20px;
        label {
          color: #899098;
          font-family: General Sans Variable;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        h1 {
          margin: 0;
          color: #fff;
          font-family: IBM Plex Mono;
          font-size: 24px;
          font-weight: 600;
          display: flex;
          align-items: center;
          sub {
            color: #fff;
            font-family: IBM Plex Mono;
            font-size: 14px;
            font-weight: 600;
            // margin-bottom: 1px;
            margin-left: 2px;
          }
        }
        .lock {
          margin-top: 20px;
          border-radius: 12px;
          border: 1px solid #fff;
          background: #3b5aff;
          width: 100%;
          height: 49.223px;
          border: none;
          color: #fff;
          font-family: General Sans Variable;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          &:disabled {
            opacity: 0.5;
          }
        }
        .withdraw {
          margin-top: 20px;
          border-radius: 12px;
          border: 1px solid #fff;
          background: #e48900;
          height: 49.223px;
          width: 100%;
          border: none;
          color: #fff;
          font-family: General Sans Variable;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .box {
    .head {
      margin-bottom: 30px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-size: 20px;
        img {
          height: 20px;
        }
      }
      p {
        font-size: 16px;
        span {
          font-size: 16px;
        }
      }
    }
    .infoBarOuter {
      margin: 15px 0;
      .infoBar {
        padding: 10px 0;
        flex-direction: column;
        gap: 10px;
        .leftSide {
          padding: 7px 15px !important;
          .timer {
            gap: 10px;
            padding-right: 10px;
            width: fit-content;
            &.closed {
              .infoIcon {
                height: 16px;
                border-right: 1px solid #252525;
                padding-right: 20px;
              }
              h5 {
                color: #fff;
                font-size: 14px;
                margin: 0;
              }
            }
            span {
              color: #fff;
              font-size: 16px;

              sub {
                font-size: 12px;
              }
            }
          }
          .info {
            min-width: 100px;
            padding-left: 20px;
            border-left: 1px solid #2a2a2a;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .rightSide {
          width: 100%;
          align-items: center;
          border: 1px solid #2b313a;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          padding: 9px 9px;
          .walletAddress {
            margin-left: 10px;
            button {
              background: transparent !important;
              border: none !important;
              color: #fff !important;
            }
          }
          .coin {
            align-items: center;
            background: #1b1c1e;
            border-radius: 8px;
            color: #fff;
            display: flex;
            margin-right: 0;
            padding: 7px 15px;
            .bullIcon {
              height: 20px;
              margin-right: 10px;
            }
            .symbol {
              color: #8e98a6;
              margin-left: 5px;
            }
          }
        }
      }
    }
    .terms {
      gap: 20px;
      .term {
        border-radius: 16px;
        .header {
          padding: 10px 20px;
          h1 {
            font-size: 18px;
          }
          p {
            padding: 5px 15px;
            font-size: 14px;
          }
        }
        .info {
          padding: 10px;
          label {
            margin-bottom: 5px;
            font-size: 14px;
          }
          h1 {
            font-size: 16px;
            align-items: center;
            sub {
              margin-bottom: 2px;
              margin-left: 2px;
              font-size: 14px;
            }
          }
          .lock {
            margin-top: 15px;
            height: 44px;
            font-size: 16px;
          }
          .withdraw {
            background: #e48900;
            margin-top: 15px;
            height: 44px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
