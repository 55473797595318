nav {
  border-bottom: 1px solid #373737;
  .navBar {
    display: flex;
    height: 74px;
    justify-content: space-between;
    align-items: center;
    img {
      cursor: pointer;
    }
    a {
      text-decoration: none;
      border-radius: 43px;
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      font-size: 1.1rem;
      font-weight: 500;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      transition: 250ms;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
    .disconnectButton {
      background-color: rgba(255, 0, 0, 0.6);
      border: none;
      color: #fff;
      height: 44px;
      padding: 10px 30px;
      font-size: 16px;
      font-family: General Sans Variable;
      font-weight: 600;
      border-radius: 10px;
      &:hover {
        background-color: rgba(255, 0, 0, 1);
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  nav {
    .navBar {
      height: 54px;
      .disconnectIcon {
        background: transparent;
        border: none;
        img {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}
