.box {
  border-radius: 26px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  background: #101010;
  margin: auto;
  text-align: center;
  h1 {
    padding: 20px 60px;
    display: flex;
    align-items: center;
    color: #fff;
    font-family: General Sans Variable;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid #181818;
    margin: 0;
    gap: 10px;
  }
  .banner {
    margin: 30px 0;
  }
  .connectButton {
    margin: 0 auto;
    display: flex;
    width: 80%;
    height: 53px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #fff;
    background: #3b5aff;
    border: none;
    color: #fff;
    font-family: General Sans Variable;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: 250ms;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  .info {
    margin: 30px auto;
    color: #899098;
    font-family: General Sans Variable;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 8px;
    background: #171717;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 53px;
    width: 80%;
  }
}

@media only screen and (max-width: 900px) {
  .box {
    width: 100%;
    h1 {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      font-size: 16px;
      img {
        height: 16px;
      }
    }
    .banner {
      margin: 30px 0;
      height: 180px;
    }
    .connectButton {
      width: 90%;
      height: 44px;
      font-size: 14px;
    }
    .info {
      margin: 20px auto;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      height: auto;
      text-align: left;
      width: 90%;
      padding: 10px;
    }
  }
}
